const ls = {
  // Get value from localstorage
  get(pram) {
    if (pram === null || pram === undefined) {
      this.log("invalid prop");
      return;
    }
    return localStorage.getItem(pram);
  },

  // Store value/property in localstorage
  set(pram, prop) {
    if (pram === null || pram === undefined) {
      this.log("invalid prop");
      return;
    }
    localStorage.setItem(pram, prop);
  },

  // Remove item from localstorage
  rem(pram) {
    if (pram === null || pram === undefined) {
      this.log("invalid prop");
      return;
    }
    localStorage.removeItem(pram);
  },

  // Clears localstorage
  clear() {
    localStorage.clear();
  },

  //console.log without 'console'
  log(pram) {
    console.log(pram);
  },
};

export default ls;
