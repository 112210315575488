import "./../styles/App.scss";

import React, { useState } from "react";
import RICIBs from "react-individual-character-input-boxes";
import { useHistory } from "react-router-dom";

import data from "../const/data";
import { gaLog, pageView } from "../util/common";
import ls from "../util/localStorage";

function Q1() {
  const q = data[1];
  const history = useHistory();
  const [value, setValue] = useState("");
  const [email, setEmail] = useState();
  const answerLength = q.choices[0].item.length;

  React.useEffect(() => {
    ls.set("q1", false);
    setEmail(ls.get("email"));
    pageView(window.location.pathname + window.location.search);
  }, []);

  // handle val
  const handler = () => {
    gaLog("Button Click", "User Clicked on Submit");
    if (value !== q.choices[0].item) {
      ls.set("q1", false);
      handleRoute();
    } else {
      ls.set("q1", true);
      handleRoute();
    }
  };

  // set current value of inoutbox to state
  const handleOutput = (e) => {
    setValue(e);
  };

  // navigate
  const handleRoute = () => {
    email === undefined || email === null
      ? history.push("/score")
      : history.push("/finish");
  };

  return (
    <div className="page">
      <div className="af-box-input">
        <RICIBs
          amount={answerLength}
          autoFocus={true}
          handleOutputString={(e) => handleOutput(e)}
          inputRegExp={/^[A-Za-z]$/}
        />
      </div>
      <br />
      <p className="af-para q1">{q.question}</p>
      <br />
      <br />
      <button onClick={() => handler()} className="af-button">
        Submit
      </button>
    </div>
  );
}

export default Q1;
