import "./../styles/App.scss";

import React from "react";
import { useHistory } from "react-router-dom";

import title from "./../assets/title.gif";
import { linkHandle } from "./../const/common";
import { gaInitialize, pageView, gaLog } from "../util/common";

function App() {
  const history = useHistory();

  React.useEffect(() => {
    gaInitialize();
    pageView("/");
  }, []);

  const handleRoute = () => {
    gaLog("Button Click", "User Clicked on Play Now");
    history.push("/q1");
  };

  return (
    <div className="page">
      <img
        src={title}
        alt="title"
        className="af-header img"
        onClick={() => linkHandle("https://www.artfervour.com/")}
      />
      {/* <h1 className="af-header h1">Word Puzzle</h1> */}
      <p className="af-para">Welcome to an Art Fervour mini game.</p>
      <br />
      <br />
      <button onClick={() => handleRoute()} className="af-button">
        Play Now
      </button>
    </div>
  );
}

export default App;
