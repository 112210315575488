import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";

import App from "./components/App";
import Finish from "./components/Finish";
import Q1 from "./components/Q1";
import Score from "./components/Score";
import * as serviceWorker from "./serviceWorker";

// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div className="index">
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/q1">
            <Q1 />
          </Route>
          <Route path="/score">
            <Score />
          </Route>
          <Route path="/finish">
            <Finish />
          </Route>
        </AnimatedSwitch>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
