import "./../styles/Finish.scss";

import { Button } from "@zeit-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Facebook, Whatsapp } from "react-social-sharing";

import { gaLog, linkHandle, pageView } from "../util/common";
import ls from "../util/localStorage";

const Finish = () => {
  const [copySuccess, setCopySuccess] = useState("Copy link");
  const [score, setScore] = useState();
  const history = useHistory();

  useEffect(() => {
    const q1 = ls.get("q1");
    if (q1 === "true") {
      setScore(true);
    } else {
      setScore(false);
    }
    pageView(window.location.pathname + window.location.search);
  }, []);

  // function to copy
  const copyToClipBoard = async () => {
    gaLog("Button Click", "User Clicked on Copy To Clipboard");
    try {
      await navigator.clipboard.writeText(
        "https://word-puzzle.artfervour.com/"
      );
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const navigateHistory = (path) => {
    history.push(path);
  };

  return (
    <div className="page">
      {score ? (
        <h1
          className="af-header h1"
          onClick={() => linkHandle("https://www.artfervour.com/")}
        >
          CELEBRATE... YOU ARE AN ART WIZARD!
        </h1>
      ) : (
        <h1 className="af-header h1 h2">
          Oops! Sorry that's not the right answer.
        </h1>
      )}
      <p className="af-para">
        <span className="tryAgain" onClick={() => navigateHistory("/")}>
          Try again
        </span>
      </p>
      <p className="af-para" style={{ fontWeight: 500 }}>
        Challenge Your Friends to Play
      </p>
      <div className="shareContainer">
        <Facebook link="https://word-puzzle.artfervour.com/" />
        <Whatsapp link="https://word-puzzle.artfervour.com/" />
        <br />
        <Button
          type="secondary-light"
          className="copy-button"
          onClick={() => copyToClipBoard()}
          style={{ transition: "all 0.3ms ease-in-out" }}
        >
          {copySuccess}
        </Button>
      </div>

      <br />
      <br />
      <p className="af-para">Checkout our other AF Games</p>
      <br />
      <button
        onClick={() => linkHandle("https://guesstheartist.artfervour.com/")}
        className="af-button"
      >
        Guess The Artist
      </button>
      <br />
      <button
        onClick={() => linkHandle("https://hangman.artfervour.com/")}
        className="af-button"
      >
        Art Hangman
      </button>
    </div>
  );
};

export default Finish;
