import "./../styles/App.scss";

import { Spinner } from "@zeit-ui/react";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ls from "../util/localStorage";
import { pageView, gaLog } from "../util/common";

const Score = () => {
  const history = useHistory();
  const [value, setValue] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbxZvr9-RhgFSKIpC45KaJQg6E3O3xgYucpIKilaGOTKM1KROWlA/exec";
  const form = document.getElementById("mailForm");

  React.useEffect(() => {
    pageView(window.location.pathname + window.location.search);
  }, []);

  const handleRoute = () => {
    history.push("/finish");
  };

  const handleMail = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
    const check = EmailValidator.validate(e.target.value);
    if (check === true) {
      setValue(true);
    } else if (check === false) {
      setValue(false);
    }
  };

  async function handleSubmit(e) {
    gaLog("Button Click", "User Clicked on Submit Email");
    e.preventDefault();
    console.log(form);
    console.log(new FormData(form));
    ls.set("email", email);
    setLoading(true);
    await fetch(scriptURL, { method: "POST", body: new FormData(form) })
      .then((response) => console.log("Success!", response))
      .catch((error) => console.error("Error!", error.message));
    setLoading(false);
    handleRoute();
  }

  return loading ? (
    <div className="page">
      <Spinner size="large" style={{ transform: "scale(1.4)" }} />
      <br />
      <p className="af-para" style={{ opacity: 0.75, fontWeight: 500 }}>
        Sending network request...
      </p>
    </div>
  ) : (
    <div className="page">
      {/* <h1 className="af-header h1"  onClick={()=>linkHandle('https://www.artfervour.com/')}></h1> */}
      <p className="af-para"> Enter your E-mail to find out the answer.</p>
      <br />
      <br />
      <form onSubmit={handleSubmit} id="mailForm">
        <input
          required
          name="email"
          type="email"
          placeholder="example@domain.com"
          className="af-input"
          onChange={(e) => handleMail(e)}
        ></input>
        <br />
        <br />
        <br />
        <button
          className="af-button"
          type="submit"
          disabled={value === undefined || value === false ? true : false}
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default Score;
