export default {
  1: {
    question: `Known for his popular painting \ntitled 'The British Raj', this \nartist was commonly referred \nto as the Picasso of India.`,
    choices: [
      {
        id: 1,
        // item: "Mrinalini Mukherjee",
        item: "mfhusain",
        answer: true,
      },
    ],
  },
};
